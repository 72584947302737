import React from 'react';
import Sidebar from './components/Sidebar';
import MobileSidebar from './components/MobileSidebar';

const SidebarWrapper = React.memo(() => (
    <React.Fragment>
        <Sidebar />
        <MobileSidebar />
    </React.Fragment>
));

export default SidebarWrapper;
