import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from '@reach/router';

const propTypes = {
    className: PropTypes.string
};

const defaultProps = {
    className: ''
};

const Logotype = React.memo(props => {
    const { className } = props;

    return (
        <Link to="/" className={classNames('navbar-brand', className)}>
            <img
                src="/img/blocket-fordon-logo-white.svg"
                height="42"
                className="navbar-brand-logo"
                alt="Blocket Fordon Logotype"
            />
        </Link>
    );
});

Logotype.defaultProps = defaultProps;
Logotype.propTypes = propTypes;

export default Logotype;
