class Fetch {
    static createFetch(fetch, { baseUrl }) {
        return (url, options) => {
            const defaultHeaders = {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json'
            };

            const defaults = {
                mode: 'same-origin',
                credentials: 'same-origin'
            };

            return fetch(`${baseUrl}${url}`, {
                ...defaults,
                ...options,
                headers: {
                    ...defaultHeaders,
                    ...options.headers
                }
            });
        };
    }

    constructor(fetch, { baseUrl }) {
        const fetchInstance = Fetch.createFetch(fetch, { baseUrl });

        return fetchInstance;
    }
}

export default Fetch;
