import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@bytbil/bootstrap-react';
import NavigationOffcanvas from '../../Navigation';

const propTypes = {
    offcanvas: PropTypes.bool.isRequired,
    toggleOffcanvas: PropTypes.func.isRequired,
    setOffcanvasCollapse: PropTypes.func.isRequired,
    offcanvasCollapse: PropTypes.shape({
        id: PropTypes.string,
        show: PropTypes.bool.isRequired
    }).isRequired
};

const MobileOffcanvas = React.memo(props => {
    const { offcanvas, toggleOffcanvas, setOffcanvasCollapse, offcanvasCollapse } = props;

    return (
        <Navbar
            className={[
                'navbar-mobile-menu',
                'd-lg-none',
                'justify-content-end',
                'navbar-panel',
                'panel-md',
                'fade-in',
                'bg-dark-alt',
                offcanvas ? 'show' : ''
            ].join(' ')}
            id="navbar-nav"
            role="navigation"
            aria-expanded="false"
        >
            <div className="navbar-mobile-scroll">
                <NavigationOffcanvas
                    toggleOffcanvas={toggleOffcanvas}
                    setOffcanvasCollapse={setOffcanvasCollapse}
                    offcanvasCollapse={offcanvasCollapse}
                    className="d-flex navbar-nav navbar-items fs-4"
                />
            </div>
        </Navbar>
    );
});

MobileOffcanvas.propTypes = propTypes;

export default MobileOffcanvas;
