const navigationLinks = [
    {
        id: 'dealer',
        icon: 'fa-home',
        text: 'Handlare',
        isHeading: true,
        subItems: [
            {
                id: 'dealer-search',
                text: 'Sök handlare',
                to: '/'
            },
            {
                id: 'dealer-groups',
                text: 'Handlargrupper',
                to: '/dealer-groups'
            },
            {
                id: 'dealer-privacy',
                text: 'Privacy',
                to: '/dealer/privacy'
            }
        ]
    },
    {
        id: 'ads',
        icon: 'fa-car',
        text: 'Annonser',
        isHeading: true,
        subItems: [
            {
                id: 'offer-search',
                text: 'Annonssök',
                to: '/offer-search'
            },
            {
                id: 'object-history',
                text: 'Objekthistorik',
                to: '/object-history'
            },
            {
                id: 'pro-import-api',
                text: 'Pro Import API',
                to: '/pro-import-api'
            }
        ]
    },
    {
        id: 'ftp',
        icon: 'fa-file',
        text: 'FTP',
        isHeading: true,
        subItems: [
            {
                id: 'ftp-users',
                text: 'Användare',
                to: '/ftp-users',
                isHeading: false
            }
        ]
    },
    {
        id: 'api',
        icon: 'fa-database',
        text: 'API',
        isHeading: true,
        subItems: [
            {
                id: 'api-users',
                text: 'Användare',
                to: '/api-users'
            },
            {
                id: 'api-groups',
                text: 'Grupper',
                to: '/api-groups'
            },
            {
                id: 'jwt-decode',
                text: 'Decode JWT-token',
                to: '/jwt-decode'
            }
        ]
    },
    {
        id: 'vehicle-info',
        icon: 'fa-info',
        text: 'Fordon',
        isHeading: true,
        subItems: [
            {
                id: 'object-data',
                text: 'Transportstyrelsen',
                to: '/object-data'
            },
            {
                id: 'brands',
                text: 'Märken & modeller',
                to: '/brands'
            }
        ]
    },

    {
        id: 'admin',
        icon: 'fa-info',
        text: 'Blocket Admin',
        isHeading: true,
        subItems: [
            {
                id: 'users',
                text: 'Användare',
                to: '/users'
            },
            {
                id: 'admin-alert',
                text: 'Admin Alert',
                to: '/admin/admin-alert'
            }
        ]
    },
    {
        id: 'Toolbox',
        icon: 'fa-info',
        text: 'Toolbox',
        isHeading: true,
        subItems: [
            {
                id: 'create-user',
                text: 'Skapa användare',
                to: '/admin/user/add'
            },
            {
                id: 'change-password',
                text: 'Ändra lösenord',
                to: '/admin/change-password'
            },
            {
                id: 'totp',
                text: 'TOTP',
                to: '/totp'
            }
        ]
    },
    {
        id: 'links',
        icon: 'fa-external-link',
        text: 'Länkar',
        isHeading: true,
        subItems: [
            {
                id: 'workshop-admin',
                text: 'Verkstadsadmin',
                icon: 'fa-external-link',
                to: 'https://app3.bytbil.com/VerkstadsAdmin/Login.aspx',
                external: true
            },
            {
                id: 'price-tag-admin',
                text: 'Prisskyltsadmin',
                icon: 'fa-external-link',
                to: 'https://admin.bytbil.com/pris/',
                external: true
            },
            {
                id: 'finance-admin',
                text: 'Finansadmin',
                icon: 'fa-external-link',
                to: 'https://finans.bytbil.com/admin_finans.cgi',
                external: true
            },
            {
                id: 'access-package-admin',
                text: 'Accesspaketsadmin',
                icon: 'fa-external-link',
                to: 'https://accesspaket.bytbilcms.com/wp/wp-admin/network/sites.php',
                external: true
            }
        ]
    },
    {
        id: 'account',
        icon: 'fa-user',
        to: 'account',
        text: 'Ditt konto',
        isHeading: true,
        hideInSidebar: true,
        subItems: [
            {
                id: 'logout',
                to: '#logout',
                text: 'Logga ut'
            }
        ]
    }
];

export default navigationLinks;
