import gql from 'graphql-tag';

export default gql`
    {
        tokens {
            accessToken
            refreshToken
        }
    }
`;
