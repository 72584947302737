import { fromPromise } from 'apollo-link';
import { onError } from 'apollo-link-error';
import createApolloClient from 'core/createApolloClient';

export default class ErrorLink {
    constructor(getNewToken) {
        return onError(({ graphQLErrors, networkError, operation, forward }) => {
            if (graphQLErrors) {
                const isAuthenticationError = graphQLErrors.find(error => error.name === 'AuthenticationRequiredError');

                if (isAuthenticationError) {
                    const client = createApolloClient();
                    return fromPromise(getNewToken(client)).flatMap(tokens => {
                        const oldHeaders = operation.getContext().headers;
                        operation.setContext({
                            headers: {
                                ...oldHeaders,
                                'x-access-Token': tokens.accessToken,
                                'x-refresh-Token': tokens.refreshToken
                            }
                        });

                        return forward(operation);
                    });
                }
            }

            if (networkError) {
                console.info(`[Network error]: ${networkError}`);
            }

            return undefined;
        });
    }
}
