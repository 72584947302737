import React from 'react';
import PropTypes from 'prop-types';
import { Toast } from '@bytbil/general-react';

class ToastContainer extends React.PureComponent {
    static propTypes = {
        hideToast: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        toast: PropTypes.shape({
            message: PropTypes.string,
            status: PropTypes.string,
            timeout: PropTypes.number
        }).isRequired
    };

    timeout = undefined;

    componentDidUpdate() {
        if (this.props.toast.message) {
            clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.props.hideToast();
            }, this.props.toast.timeout);
        }
    }

    render() {
        const { toast, show } = this.props;

        return <Toast showToast={show} toast={toast} />;
    }
}

export default ToastContainer;
