import React from 'react';
import produce from 'immer';
import { Navbar, NavbarToggler } from '@bytbil/bootstrap-react';
import Logotype from 'components/Logotype';
import { UserContext } from '../../../context/UserContext';
import MobileOffcanvas from './MobileOffCanvas';

class MobileSidebar extends React.PureComponent {
    state = {
        showOffcanvas: false,
        offcanvasCollapse: {
            id: undefined,
            show: false
        }
    };

    toggleOffcanvas = () => {
        let className;

        if (typeof window !== 'undefined') {
            className = !this.state.showOffcanvas ? 'offcanvas-open' : '';

            document.body.className = className;
            document.documentElement.className = className;
        }

        this.setState(
            produce(draft => {
                if (draft.showOffcanvas) {
                    draft.offcanvasCollapse.id = undefined;
                    draft.offcanvasCollapse.show = false;
                }

                draft.showOffcanvas = !draft.showOffcanvas;
            })
        );
    };

    setOffcanvasCollapse = (id, show) => {
        this.setState(
            produce(draft => {
                draft.offcanvasCollapse.id = id;
                draft.offcanvasCollapse.show = show;
            })
        );
    };

    render() {
        const { showOffcanvas, offcanvasCollapse } = this.state;

        return (
            <React.Fragment>
                <MobileOffcanvas
                    offcanvasCollapse={offcanvasCollapse}
                    setOffcanvasCollapse={this.setOffcanvasCollapse}
                    toggleOffcanvas={this.toggleOffcanvas}
                    offcanvas={showOffcanvas}
                />
                <Navbar tag="header" className="bg-dark-alt header-main d-lg-none fixed-top" dark>
                    <div className="container d-flex justify-content-between">
                        <Logotype />
                        <UserContext.Consumer>
                            {user =>
                                user.authenticated && (
                                    <NavbarToggler
                                        className={[
                                            'flex-last',
                                            'align-self-center',
                                            'navbar-toggler',
                                            'transition',
                                            'd-lg-none',
                                            'pl-2',
                                            'pl-sm-3',
                                            showOffcanvas ? 'active' : ''
                                        ].join(' ')}
                                        onClick={this.toggleOffcanvas}
                                    />
                                )
                            }
                        </UserContext.Consumer>
                    </div>
                </Navbar>
            </React.Fragment>
        );
    }
}

export default MobileSidebar;
