import React, { createContext, Dispatch, useReducer } from 'react';
import Reducer from './Reducer';
import InitialStateType from './types';

const initialState = {
    dealerSearch: {
        called: false,
        active: false,
        value: ''
    }
};

interface Props {
    children: React.ReactNode;
}

export const Context = createContext<{ state: InitialStateType; dispatch: Dispatch<any> }>({
    state: initialState,
    dispatch: () => null
});

const Store = ({ children }: Props) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export default Store;
