import React from 'react';
import Helmet from 'react-helmet-async';
import produce from 'immer';
import PropTypes from 'prop-types';
import MainContainer from '../MainContainer';

class RouteErrorBoundary extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired
        }).isRequired
    };

    state = {
        hasError: false
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    shouldComponentUpdate() {
        if (this.state.hasError) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            // eslint-disable-next-line
            this.setState(
                produce(draft => {
                    draft.hasError = false;
                })
            );
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <React.Fragment>
                    <Helmet>
                        <title>Sidan kunde inte visas</title>
                    </Helmet>
                    <MainContainer>
                        <h1>Sidan kunde inte visas!</h1>
                        <p>Prova igen om en liten stund.</p>
                    </MainContainer>
                </React.Fragment>
            );
        }

        return this.props.children;
    }
}

export default RouteErrorBoundary;
