import React from 'react';
import PropTypes from 'prop-types';

class ScrollToTop extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired
    };

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return null;
    }
}

export default ScrollToTop;
