import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { UserContext } from '../../context/UserContext';

const propTypes = {
    id: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

const defaultProps = {
    className: ''
};

class NavigationLink extends React.PureComponent {
    static contextType = UserContext;

    handleOnClick = () => {
        this.props.onClick();

        if (this.props.id === 'logout') {
            this.context.actions.logout();
        }
    };

    render() {
        const { to, className, children } = this.props;

        return (
            <Link to={to} onClick={this.handleOnClick} className={className}>
                {children}
            </Link>
        );
    }
}

NavigationLink.propTypes = propTypes;
NavigationLink.defaultProps = defaultProps;

export default NavigationLink;
