import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    name: PropTypes.string.isRequired,
    beta: PropTypes.bool
};

const SideItemHeading = React.memo(props => {
    const { name, beta } = props;

    return (
        <strong className="text-white mt-3">
            {name}
            {beta && <sup> beta</sup>}
        </strong>
    );
});

SideItemHeading.propTypes = propTypes;

export default SideItemHeading;
