import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Location, Link } from '@reach/router';
import { navItemTypes } from '../../../core/types';
import SideItemHeading from './SideItemHeading';

const propTypes = {
    items: PropTypes.arrayOf(navItemTypes.navitem).isRequired
};

const className = (location, item) => classNames('text-white', 'ml-2', location.pathname === item.to && 'active');

const SidebarNavigation = React.memo(({ items }) => (
    <Location>
        {({ location }) => (
            <ul className="pl-0">
                {items.map(
                    item =>
                        !item.hideInSidebar && (
                            <li key={item.id}>
                                {item.isHeading && <SideItemHeading name={item.text} beta={item.beta} />}
                                {!item.isHeading && (
                                    <React.Fragment>
                                        {item.icon && <i className={`fa ${item.icon} ml-2 text-white`} />}
                                        {item.external ? (
                                            <a href={item.to} className={className(location, item)}>
                                                {item.text}
                                            </a>
                                        ) : (
                                            <Link to={item.to} className={className(location, item)}>
                                                {item.text}
                                            </Link>
                                        )}
                                    </React.Fragment>
                                )}
                                {item.subItems && <SidebarNavigation items={item.subItems} />}
                            </li>
                        )
                )}
            </ul>
        )}
    </Location>
));

SidebarNavigation.propTypes = propTypes;

export default SidebarNavigation;
