/* eslint-disable react/no-unused-state, no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import { ApolloClient } from '@apollo/client';
import universalFetch from 'core/universalFetch';

import logoutMutation from './mutations/logout';

export const UserContext = React.createContext();

class UserContextComponent extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        client: PropTypes.instanceOf(ApolloClient).isRequired,
        authenticated: PropTypes.bool.isRequired
    };

    state = {
        authenticated: this.props.authenticated,
        actions: {
            login: async (accessToken, refreshToken) => {
                await universalFetch('/tokens', {
                    method: 'POST',
                    body: JSON.stringify({
                        accessToken,
                        refreshToken
                    })
                });

                this.setState(
                    produce(draft => {
                        draft.authenticated = true;
                    })
                );
            },
            logout: async () => {
                this.setState(
                    produce(draft => {
                        draft.authenticated = false;
                    })
                );

                await this.props.client.mutate({
                    mutation: logoutMutation
                });

                await universalFetch('/tokens', {
                    method: 'DELETE'
                });
            }
        }
    };

    render() {
        return <UserContext.Provider value={this.state}>{this.props.children}</UserContext.Provider>;
    }
}

export default UserContextComponent;
