import React from 'react';
import produce from 'immer';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from '@bytbil/bootstrap-react';
import { UserContext } from '../../../context/UserContext';

class SidebarLogout extends React.PureComponent {
    state = {
        showUserDropdown: false
    };

    toggleUserDropdown = () => {
        this.setState(
            produce(draft => {
                draft.showUserDropdown = !draft.showUserDropdown;
            })
        );
    };

    render() {
        const { showUserDropdown } = this.state;

        return (
            <UserContext.Consumer>
                {user => (
                    <Dropdown
                        className="sidebar-user"
                        isOpen={showUserDropdown}
                        toggle={this.toggleUserDropdown}
                        inNavbar
                    >
                        <DropdownToggle tag="a" className="p-2 text-white d-block" role="button" data-toggle="dropdown">
                            <i className="fa fa-user mr-1" />
                            <i className={`fa fa-chevron-${showUserDropdown ? 'up' : 'down'} fs-1`} />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-fluid" right>
                            <DropdownItem name="logout" onClick={user.actions.logout}>
                                <i className="fa fa-lock mr-2" />
                                Logga ut
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                )}
            </UserContext.Consumer>
        );
    }
}

export default SidebarLogout;
