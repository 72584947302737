import universalFetch from 'core/universalFetch';
import { ApolloClient, ApolloLink } from '@apollo/client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { HttpLink } from 'apollo-link-http';
import apolloLogger from 'apollo-link-logger';
import getTokensQuery from './getTokensQuery';
import ErrorLink from './errorLink';
import createCache from './createCache';

const cache = createCache();

const getNewToken = async client => {
    const { data } = await client.query({
        query: getTokensQuery
    });

    await universalFetch('/set_tokens', {
        method: 'POST',
        body: JSON.stringify(data.tokens)
    });

    return data.tokens;
};

export default function createApolloClient() {
    return new ApolloClient({
        link: ApolloLink.from([
            apolloLogger,
            new ApolloLinkTimeout(30000), // 30 seconds is the default Cloudfront timeout value so increasing this value will not make a difference
            new ErrorLink(getNewToken),
            new HttpLink({
                uri: `/graphql`,
                credentials: 'include',
                fetch: window.fetch
            })
        ]),
        cache: cache.restore(window.App.apolloData),
        queryDeduplication: true,
        connectToDevTools: true
    });
}
