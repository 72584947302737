import React from 'react';
import { Container } from '@bytbil/bootstrap-react';
import Logotype from 'components/Logotype';
import { UserContext } from '../../../context/UserContext';
import SidebarNavigation from './SidebarNavigation';
import SidebarLogout from './SidebarLogout';
import navigationLinks from '../../../core/navigationLinks';

const Sidebar = React.memo(() => (
    <UserContext.Consumer>
        {user =>
            user.authenticated && (
                <div className="d-none d-lg-flex sidebar bg-dark-alt">
                    <Container>
                        <Logotype className="mb-3 mt-3" />
                        <React.Fragment>
                            <SidebarLogout />
                            <div className="ml-auto mb-0 pl-0 d-flex flex-column side-nav">
                                <SidebarNavigation items={navigationLinks} />
                            </div>
                        </React.Fragment>
                    </Container>
                </div>
            )
        }
    </UserContext.Consumer>
));

export default Sidebar;
