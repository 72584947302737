import React from 'react';
import PropTypes from 'prop-types';
import { PageLoader } from '@bytbil/general-react';
import MainContainer from '../MainContainer';

const propTypes = {
    show: PropTypes.bool.isRequired
};

const RoutePageLoader = React.memo(props => {
    const { show } = props;

    return (
        <MainContainer className="position-relative route-pageloader">
            <PageLoader show={show} />
        </MainContainer>
    );
});

RoutePageLoader.propTypes = propTypes;

export default RoutePageLoader;
