import React from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import Toast from '../../components/Toast';

export const ToastContext = React.createContext();

class ToastContextComponent extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired
    };

    state = {
        show: false,
        message: undefined,
        status: undefined,
        timeout: undefined
    };

    showToast = (message, status = 'success', timeout = 2000) => {
        this.setState(
            produce(draft => {
                draft.show = true;
                draft.message = message;
                draft.status = status;
                draft.timeout = timeout;
            })
        );
    };

    hideToast = () => {
        this.setState(
            produce(draft => {
                draft.show = false;
            })
        );
    };

    render() {
        const { children } = this.props;
        const { show, message, status, timeout } = this.state;

        return (
            <React.Fragment>
                <Toast toast={{ message, status, timeout }} show={show} hideToast={this.hideToast} />
                <ToastContext.Provider value={{ showToast: this.showToast }}>{children}</ToastContext.Provider>
            </React.Fragment>
        );
    }
}

export default ToastContextComponent;
