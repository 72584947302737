import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Location } from '@reach/router';
import CollapseItem from './CollapseItem';
import navigationLinks from '../../core/navigationLinks';
import NavigationLink from './NavigationLink';

const propTypes = {
    toggleOffcanvas: PropTypes.func.isRequired,
    setOffcanvasCollapse: PropTypes.func.isRequired,
    offcanvasCollapse: PropTypes.shape({
        id: PropTypes.string,
        show: PropTypes.bool.isRequired
    }).isRequired,
    className: PropTypes.string,
    linkClassName: PropTypes.string
};

const defaultProps = {
    className: '',
    linkClassName: ''
};

const NavigationOffcanvas = React.memo(props => {
    const { toggleOffcanvas, setOffcanvasCollapse, offcanvasCollapse, className, linkClassName } = props;

    return (
        <Location>
            {({ location }) => (
                <ul className={className}>
                    {navigationLinks.map(item =>
                        item.subItems && item.subItems.length > 0 ? (
                            <div className="nav-item-parent" key={`collapse-item-${item.id}`}>
                                <CollapseItem
                                    item={item}
                                    setOffcanvasCollapse={setOffcanvasCollapse}
                                    offcanvasCollapse={offcanvasCollapse}
                                >
                                    <ul className="navbar-nav d-lg-none">
                                        {item.subItems.map(subItem => (
                                            <li className="nav-item" key={subItem.id}>
                                                {subItem.external ? (
                                                    <a
                                                        className={classNames(
                                                            'nav-link fs-3',
                                                            linkClassName,
                                                            location.pathname === subItem.to && 'active'
                                                        )}
                                                        id={subItem.id}
                                                        href={subItem.to}
                                                        onClick={toggleOffcanvas}
                                                    >
                                                        {subItem.icon && <i className={`fa ${subItem.icon} pr-2`} />}
                                                        {subItem.text}
                                                    </a>
                                                ) : (
                                                    <NavigationLink
                                                        className={classNames(
                                                            'nav-link fs-3',
                                                            linkClassName,
                                                            location.pathname === subItem.to && 'active'
                                                        )}
                                                        id={subItem.id}
                                                        to={subItem.to}
                                                        onClick={toggleOffcanvas}
                                                    >
                                                        {subItem.icon && <i className={`fa ${subItem.icon} pr-2`} />}
                                                        {subItem.text}
                                                    </NavigationLink>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </CollapseItem>
                            </div>
                        ) : (
                            <li className="nav-item">
                                <NavigationLink
                                    className={classNames(
                                        'nav-link',
                                        linkClassName,
                                        location.pathname === item.to && 'active'
                                    )}
                                    id={item.id}
                                    to={item.to}
                                    onClick={toggleOffcanvas}
                                >
                                    {item.icon ? <i className={`fa ${item.icon} pr-2`} /> : ''}
                                </NavigationLink>
                            </li>
                        )
                    )}
                </ul>
            )}
        </Location>
    );
});

NavigationOffcanvas.propTypes = propTypes;
NavigationOffcanvas.defaultProps = defaultProps;

export default NavigationOffcanvas;
