import { Reducer } from 'react';
import InitialStateType from './types';

type StateActions = {
    type: string;
    called: boolean;
    active: boolean;
    value: string;
};

const MainReducer: Reducer<InitialStateType, StateActions> = (state, action) => {
    switch (action.type) {
        case 'DEALER_SEARCH_CALLED':
            return {
                ...state,
                dealerSearch: {
                    ...state.dealerSearch,
                    called: action.called
                }
            };
        case 'DEALER_SEARCH_ACTIVE':
            return {
                ...state,
                dealerSearch: {
                    ...state.dealerSearch,
                    active: action.active
                }
            };
        case 'DEALER_SEARCH':
            return {
                ...state,
                dealerSearch: {
                    ...state.dealerSearch,
                    value: action.value
                }
            };
        default:
            return state;
    }
};

export default MainReducer;
