import React from 'react';
import classNames from 'classnames';

interface Props {
    children: React.ReactNode;
    className?: string;
}

const MainContainer = React.memo((props: Props) => {
    const { children, className } = props;

    return (
        <main className={classNames('main-content main-content-pull-left', className)}>
            <div className="container mt-4">{children}</div>
        </main>
    );
});

export default MainContainer;
