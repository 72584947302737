import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse } from '@bytbil/bootstrap-react';
import { navItemTypes } from '../../core/types';

const propTypes = {
    item: navItemTypes.navitem.isRequired,
    children: PropTypes.node.isRequired,
    setOffcanvasCollapse: PropTypes.func.isRequired,
    offcanvasCollapse: PropTypes.shape({
        id: PropTypes.string,
        show: PropTypes.bool.isRequired
    }).isRequired
};

class CollapseItem extends React.PureComponent {
    toggle = e => {
        e.preventDefault();

        const { offcanvasCollapse, setOffcanvasCollapse, item } = this.props;
        let show = true;

        if (offcanvasCollapse.id === item.id) {
            show = !offcanvasCollapse.show;
        }

        if (item.active && typeof offcanvasCollapse.id === 'undefined') {
            show = false;
        }

        setOffcanvasCollapse(this.props.item.id, show);
    };

    render() {
        const { item, children } = this.props;

        const isOpen =
            (this.props.item.active && typeof this.props.offcanvasCollapse.id === 'undefined') ||
            (this.props.offcanvasCollapse.id === this.props.item.id && this.props.offcanvasCollapse.show);

        return (
            <React.Fragment>
                <li className="nav-item d-lg-none">
                    <div
                        onClick={this.toggle}
                        role="button"
                        tabIndex="0"
                        className={`nav-link d-flex justify-content-between ${item.active ? 'active' : ''}`}
                    >
                        <strong>{item.text}</strong>
                        <i
                            className={classNames(
                                'fa navbar-mobile-menu-arrow',
                                isOpen ? 'fa-chevron-up' : 'fa-chevron-down'
                            )}
                        />
                    </div>
                </li>
                <Collapse isOpen={isOpen}>{children}</Collapse>
            </React.Fragment>
        );
    }
}

CollapseItem.propTypes = propTypes;

export default CollapseItem;
